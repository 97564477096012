import ProgressBar from "@ramonak/react-progress-bar";
import "./Rodape.css";
import { toast } from "react-toastify";
const Rodape = ({ voltar, avancar, valorBar }) => {

    const notifyViagemNotFilled = () => toast.warn('Preencha os dados da Viagem!', {
        position: "top-right", 
        autoClose: 3000,       
        hideProgressBar: false, 
        closeOnClick: true,    
        pauseOnHover: true,    
        draggable: true,       
        progress: undefined,   
    });
    
    const notFilled = () => {
        notifyViagemNotFilled();
        return;
    }

    return (
        <section className="rodape">
            <ProgressBar
                completed={valorBar}
                bgColor="#222222"
                height="5px"
                borderRadius="0"
                isLabelVisible={false}
                labelColor="#e8090"
                baseBgColor="#fff"
                transitionTimingFunction="linear"
                animateOnRender
                maxCompleted={100}
            />
            <div className="rodapebtns">
                {voltar &&
                    <button className="button" onClick={voltar}>Voltar</button>
                }
                {
                    avancar ? 
                    (
                        <><button type="submit" className="button">Avançar</button></>
                    )
                    :
                    (
                        <><button style={{backgroundColor: '#808080'}} disabled onClick={notFilled} className="button">Avançar</button></>
                    )
                }
            </div>

        </section>
    );
}

export default Rodape;