import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { validarCPF } from "../../utils/cpfUtils";
import { format } from "date-fns";
import "./custom.css"

import validarPassaporte from "../../utils/passaporte";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, DatePicker, Input, MaskedInput } from "rsuite";
import CloseIcon from '@rsuite/icons/Close';

const CadastroAcompanhante = ({ tipoTurismo, identificacaoTitular, setIsAcomPartialFilled, setlistaAcompanhante, listaAcompanhante, setPossuiAcompanhante, isAcomPartialFilled }) => {

    const notify = () => toast.warn('Preencha os dados do Acompanhante!', {
        position: "top-right", // Posição da notificação
        autoClose: 3000,       // Fecha automaticamente após 3 segundos
        hideProgressBar: false, 
        closeOnClick: true,    
        pauseOnHover: true,    
        draggable: true,       
        progress: undefined,   
    });

    const notifyAcomIsPartialFilled = () => toast.warn('Aperte Limpar antes de fechar!', {
        position: "top-right", 
        autoClose: 3000,       
        hideProgressBar: false, 
        closeOnClick: true,    
        pauseOnHover: true,    
        draggable: true,       
        progress: undefined,   
    });

    const navigation = useNavigate();

    const [responsavel, setResponsavel] = useState(true);
    const [nomeCompletoAcom, setNomeCompletoAcom] = useState("");
    const [nome, setNome] = useState("");
    const [sobrenome, setSobrenome] = useState("");
    const [sexoAcom, setSexoAcompanhante] = useState("");
    const [nascimento, setNascimento] = useState(null);
    const [cpf, setCpf] = useState("");
    const [cpfInvalido, setCpfInvalido] = useState(false);;
    const [passaporte, setPassaporte] = useState('');
    const [passaporteInvalido, setPassaporteInvalido] = useState(false);
    const [nacionalidadeAcom, setNacionalidade] = useState("");
    const [tipoTurismoState, setTipoTurismoState] = useState("");
    const [escolaridade, setEscolaridade] = useState("");
    const [tipoIdAcomp, setTipoIdAcomp] = useState("");
    const [tipoAcomp, setTipoAcomp] = useState("Esposo(a)");
    const [telefoneacomp, setTelefoneAcomp] = useState("");
    const [newTelefoneacomp, setNewTelefoneAcomp] = useState("");
    const [emailacomp, setEmailAcomp] = useState("");
    const [isAllFilledAcom, setIsAllFilled] = useState(false);
    const [isKid, setIsKid] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    
    useEffect(() => {
        const areFieldsFilled = (fields) => fields.every((field) => !!field);

        if (isKid) {
            setCpf(`Menor: ${nome} ${sobrenome}`);
    
            const requiredFieldsKid = [sexoAcom, nome, sobrenome, nascimento];
            setIsAllFilled(areFieldsFilled(requiredFieldsKid));

            const isPartialFilled =
            sexoAcom.trim() !== "" ||
            nome.trim() !== "" ||
            sobrenome.trim() !== "" ||
            nascimento !== null;

            setIsAcomPartialFilled(isPartialFilled);
        } else {
            
            const commonFields = [telefoneacomp, sexoAcom, nome, sobrenome, nascimento];

            if (tipoIdAcomp === "cpf") {
                setIsAllFilled(areFieldsFilled([...commonFields, cpf]));
            } else if (tipoIdAcomp === "passaporte") {
                setIsAllFilled(areFieldsFilled([...commonFields, passaporte]));
            } else {
                setIsAllFilled(false); 
            }

            const isPartialFilled =
            sexoAcom.trim() !== "" ||
            nome.trim() !== "" ||
            sobrenome.trim() !== ""  ||
            nascimento !== null;

            setIsAcomPartialFilled(isPartialFilled);
        }


    }, [setIsAcomPartialFilled, telefoneacomp, sexoAcom, nome, sobrenome, nascimento, tipoIdAcomp, cpf, passaporte, identificacaoTitular, isKid]);
    
    // Preenche nome e sobrenome a partir de nomeCompletoAcom
    useEffect(() => {
        // Dividir apenas no primeiro espaço
        const [primeiraParte, ...restante] = nomeCompletoAcom.split(' ');
        const restanteString = restante.join(' ');
    
        setNome(primeiraParte);
        setSobrenome(restanteString);
    }, [nomeCompletoAcom])

    useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Checar no carregamento inicial
    window.addEventListener("resize", handleResize); // Listener para mudanças de tamanho

    return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleAddLista = () => {
        const formattedDate = format(nascimento, 'yyyy-MM-dd');
        setNascimento(formattedDate);

        if(!isAllFilledAcom){
            notify();
            return
        }

        const index = listaAcompanhante.findIndex((item) => 
            (cpf && item.cpf === cpf) && (!cpf && item.passaporte === passaporte)
        );
        
        setTelefoneAcomp(newTelefoneacomp);
        setNewTelefoneAcomp("");

        if (index !== -1) {
            listaAcompanhante[index].nome = nome;
            listaAcompanhante[index].sobrenome = sobrenome;
            listaAcompanhante[index].sexo = sexoAcom;
            listaAcompanhante[index].data_nascimento = nascimento;
            listaAcompanhante[index].cpf = cpf;
            listaAcompanhante[index].nacionalidadeAcom = nacionalidadeAcom || 'Brasileiro(a)';
            listaAcompanhante[index].escolaridade = escolaridade;
            listaAcompanhante[index].tipoIdAcomp = tipoIdAcomp;
            listaAcompanhante[index].tipoAcomp = tipoAcomp;
            listaAcompanhante[index].emailAcomp = emailacomp;
            listaAcompanhante[index].telefoneAcomp = telefoneacomp;
            listaAcompanhante[index].passaporte = passaporte;
        } else {
            listaAcompanhante.push({
                nome: nome,
                sobrenome: sobrenome,
                sexo: sexoAcom,
                data_nascimento: nascimento,
                cpf: cpf,
                nacionalidadeAcom: nacionalidadeAcom || 'Brasileiro(a)',
                escolaridade: escolaridade,
                tipoIdAcomp: tipoIdAcomp,
                tipoAcomp: tipoAcomp,
                emailAcomp: emailacomp,
                telefoneAcomp: telefoneacomp,
                passaporte: passaporte
            });
        }

        localStorage.setItem("@cadastro:Etapa3", JSON.stringify(listaAcompanhante))
        setNome("");
        setNomeCompletoAcom("");
        setSobrenome("");
        setSexoAcompanhante("");
        setNascimento(null);
        setCpf("");
        setNacionalidade("");
        setEscolaridade("");
        setTipoIdAcomp("");
        setEmailAcomp("");
        setTipoAcomp("");
        setTelefoneAcomp("")
        setNewTelefoneAcomp("");
        setIsKid(false);
        
        setIsAllFilled(false);
        setIsAcomPartialFilled(false);
        setIsKid(false);

        setPossuiAcompanhante(false);
    };

    const handleClear = () => {
        setNome("");
        setNomeCompletoAcom("")
        setSobrenome("");
        setSexoAcompanhante("");
        setNascimento(null);
        setCpf("");
        setPassaporte("");
        setNacionalidade("");
        setTipoIdAcomp("");
        setTipoAcomp("");
        setTelefoneAcomp("");
        setNewTelefoneAcomp("");
        setEmailAcomp("");
        setIsKid(false);
    }

    const handleCloseAcomForm = () => {
        if(isAcomPartialFilled){
            notifyAcomIsPartialFilled();
            return
        }
        setPossuiAcompanhante(false);
    }

    useEffect(() => {
        setTipoTurismoState(tipoTurismo)
        const interval = setInterval(() => {
            (async () => {
                const dados = JSON.parse(localStorage.getItem("@cadastro:Etapa3"));
                if (dados != null) {
                    setlistaAcompanhante(dados);
                }
            })();
        }, 1000);
        return () => clearInterval(interval);
    }, [tipoTurismo, tipoTurismoState]);

    // Valida CPF
    const handleCpfChangeAcompanhante = (value) => {
        const novoCpfAcompanhante = value;
        setCpf(novoCpfAcompanhante);
        setCpfInvalido(!validarCPF(novoCpfAcompanhante));
    }

    //Valida Passaporte
    const handlePassaporteChangeAcompanhante = (value) => {
        const novoPassaporte = value;
        setPassaporte(novoPassaporte);
        setPassaporteInvalido(!validarPassaporte(novoPassaporte));
    }    

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{ alignItems: 'center', width: '100%' }} onSubmit={() => (listaAcompanhante.length === 0 || responsavel) ? navigation("/contato") : alert("É Preciso que marque a opção de responsabilidade sobre os acompanhantes.")}>
                <div style={{width: '100%',}}>
                    
                    <div className="text1-black">Acompanhantes</div>

                    <div className="corpoacom" style={{position: 'relative'}}>
                    <Button style={{position: 'absolute', right: '0', marginRight: '10px'}} onClick={handleCloseAcomForm}>
                        <CloseIcon style={{ color: 'red', cursor: 'pointer'}}/> 
                    </Button>
                    
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem',marginTop: '2rem'}}>
                        
                        <input
                            type="checkbox" checked={isKid} onChange={item => setIsKid(item.target.checked)}
                        />
                        <h2 className="textRadio" style={{marginLeft: '1rem'}}>Selecione se for menor de 14 anos</h2>
                    </div>
                    {
                        !isKid ? 
                        (
                            <>
                                <div>
                                    <div className="titleinput">Documentação</div>
                                    <div className="col-2 flex">                            
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <input 
                                                defaultChecked 
                                                type="radio" 
                                                value="cpf" 
                                                name="ident" 
                                                checked={tipoIdAcomp === "cpf"} 
                                                onChange={item => setTipoIdAcomp(item.target.value)}  
                                            />
                                            <label style={{marginLeft: '1rem'}} className="textRadio">CPF</label>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <input type="radio" value="passaporte" name="ident" checked={tipoIdAcomp === "passaporte"} onChange={item => setTipoIdAcomp(item.target.value)} />
                                            <label style={{marginLeft: '1rem'}} className="textRadio">Passaporte</label>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                        :
                        (<></>)
                    }
                        <div className="containerbloco" class="flex">
                            {
                                !isKid ? 
                                (
                                    <>
                                        <div>
                                            {
                                                tipoIdAcomp === "cpf" &&
                                                (
                                                   <>
                                                    <div className="textInput">CPF</div>
                                                        <MaskedInput
                                                        className="cpf-input"
                                                        placeholder="Número do CPF"
                                                        mask={[
                                                            /\d/, /\d/, /\d/, '.', 
                                                            /\d/, /\d/, /\d/, '.', 
                                                            /\d/, /\d/, /\d/, '-', 
                                                            /\d/, /\d/
                                                        ]}
                                                        name="cpf"
                                                        value={cpf}
                                                        onChange={handleCpfChangeAcompanhante}
                                                        style={{width: '100%'}}
                                                        />
                                                   </>
                                                )
                                            }
                                                {cpfInvalido && tipoIdAcomp === "cpf"? (
                                                    <p style={{ color: "red" }}>CPF inválido. Por favor, verifique.</p>
                                                ): (<></>)}

                                                {tipoIdAcomp === "passaporte" &&    
                                                    (
                                                        <>
                                                        <div className="textInput">Passaporte</div>
                                                        <Input
                                                            placeholder="Número do passaporte"
                                                            value={passaporte}
                                                            onchange={handlePassaporteChangeAcompanhante}
                                                        />
                                                        </>
                                                    )
                                                }
                                            {passaporteInvalido && tipoIdAcomp === "passaporte"? (
                                                <p style={{ color: "red" }}>Passaporte inválido. Por favor, verifique.</p>
                                            ): (<></>)}
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <></>
                                )
                            }
                        </div>

                        <div className="containerbloco" style={{width: '100%', marginTop: '2rem'}}>
                            <div className="textInput">Nome Completo</div>
                            <Input
                                placeholder="Nome Completo"
                                value={nomeCompletoAcom}
                                onChange={value => setNomeCompletoAcom(value)}
                            />
                            {
                                nomeCompletoAcom && nomeCompletoAcom.trim().split(' ').length < 2 && (
                                    <><span style={{color:'red'}}>Escreva nome e sobrenome</span></>
                                )
                            }
                        </div>

                        <div style={{ display: 'flex', gap: '2rem', alignItems: 'flex-start' }}>
  
                        

                        {/* Qual seu sexo */}
                        <div  className="containerbloco" >
                            <div className="textInput">Qual seu sexo</div>
                            <div className="col-2 flex">
                            <div style={{ display: 'flex', alignItems:'center'}}>
                                <input
                                type="radio"
                                value="Masculino"
                                name="sexo"
                                checked={sexoAcom === "Masculino"}
                                onChange={(item) => setSexoAcompanhante(item.target.value)}
                                />
                                <label style={{marginLeft: '1rem'}} className="textRadio">Masculino</label>
                            </div>
                            <div  style={{ display: 'flex', alignItems:'center'}}>
                                <input
                                type="radio"
                                value="Feminino"
                                name="sexo"
                                checked={sexoAcom === "Feminino"}
                                onChange={(item) => setSexoAcompanhante(item.target.value)}
                                />
                                <label style={{marginLeft: '1rem'}} className="textRadio">Feminino</label>
                            </div>
                            <div  style={{ display: 'flex', alignItems:'center'}}>
                                <input
                                type="radio"
                                value="Outros"
                                name="sexo"
                                checked={sexoAcom === "Outros"}
                                onChange={(item) => setSexoAcompanhante(item.target.value)}
                                />
                                <label style={{marginLeft: '1rem'}} className="textRadio">Outros</label>
                            </div>
                            </div>
                        </div>
                        </div>

                        <div className="containerbloco flex" style={{width: '100%', gap: '20px'}}>


                            <div className="box">

                                <div className="textInput">Data de nascimento</div>
                                    <DatePicker
                                        onChange={newValue => {
                                            setNascimento(newValue);
                                        }}
                                        value={nascimento}
                                        style={{width: '100%'}}
                                    />
                            </div>
                            {
                                isKid ? 
                                (
                                    <></>
                                )
                                :
                                (
                                    <>
                                        <div className="box">
                                            <div className="textInput">Telefone(whatsapp)</div>
                                            <PhoneInput 
                                                country={'br'}
                                                value={telefoneacomp}
                                                onChange={(e) => setTelefoneAcomp(e)}
                                                containerStyle={{
                                                    borderRadius: '100px',
                                                    width: '100%',
                                                }}
                                                inputStyle={{
                                                    // height: '35px',
                                                    fontSize: '14px',
                                                    paddingLeft: '40px',
                                                    width: '100%',
                                                    height: '7rem'
                                                }}
                                                placeholder=""
                                            />
                                        </div>
                                    </>
                                )
                            }
                           
                        </div>
                    </div>


                    <div className="btngroup" >
                        <button className="btn limpar" type="button" onClick={() => handleClear()}>Limpar</button>
                        

                        
                        {
                            isAllFilledAcom ? 
                            (
                                <button className="btn salvar" type="button" onClick={() => handleAddLista()}>
                                    Adicionar
                                </button>
                            )
                            :
                            (
                                <button className="btn disabled" type="button" onClick={() => handleAddLista()}>
                                    Adicionar
                                </button>
                            )
                        }
                    </div>

                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}

export default CadastroAcompanhante;
