import { useNavigate } from "react-router-dom";
import { lazy, useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import LogoColor from "../../assets/image/logo_color.svg";
import axios from "axios";
import { limparIdentificacao, validarCPF } from "../../utils/cpfUtils";
import validarPassaporte from "../../utils/passaporte";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import API from "../../service/api";
import EstadoCidadePicker from "../../components/EstadoCidadeInput";
import PaisPicker from "../../components/paisPicker";
import { Checkbox, Input, Loader, MaskedInput, InputPicker, DatePicker, Button } from "rsuite";
import DateRangePicker from "../../components/RangeDataPicker";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./responsive.css";
import validaCampos from "../../components/ValidaCampos";
import CadastroAcompanhante from "../CadastroAcompanhante";
import { FaUser } from "react-icons/fa";


const CadastroVisitante = () => {
  const navigation = useNavigate();

  const [estadoufh, setEstadoUFH] = useState("");
  const [hospedagem, setHospedagem] = useState(null);
  const [nomeAcomodacao, setNomeAcomodacao] = useState("");
  const [nomeHospital, setNomeHospital] = useState("");
  const [hospital, setHospital] = useState(null);
  const [telhospedagem, setTelHospedagem] = useState("");
  const [endereco, setEndereco] = useState("");
  const [tiporoteiro, setTipoRoteiro] = useState("");
  const [viajandoCom, setviajandoCom] = useState("");
  const [PetFriendly, setPetFriendly] = useState("1");
  const [id_municipio, setIdmunicipio] = useState(1);
  const [agencia, setAgencia] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [listaAcompanhante, setListaAcompanhante] = useState([]);
  
  const [possuiAcompanhante, setPossuiAcompanhante] = useState(false);
  const [nome, setNome] = useState("");
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [dates, setDates] = useState([])
  const [conhecemunicipio, setConheceMunicipio] = useState(null);
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [identificacao, setIdentificacao] = useState("");
  const [identificacaoTitular, setIdentificacaoTitular] = useState(identificacao);
  const [passaporte, setPassaporte] = useState("");
  const [passaporteInvalido, setPassaporteInvalido] = useState("");
  const [cnpjEmpresa, setCnpjEmpresa] = useState("");
  const [cpfInvalido, setCpfInvalido] = useState(false);
  const [tipoIdentificacao, setTipoIdentificacao] = useState("cpf");
  const [sexo, setSexo] = useState("");
  const [compartilhaDados, setCompartilhaDados] = useState(true);
  const [nascimento, setNascimento] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isAcomPartialFilled, setIsAcomPartialFilled] = useState(false);

  const [cnpj, setCnpj] = useState(null);

  const [tipoTurismo, setTipoTurismo] = useState("Turismo De Lazer");
  const [empresa, setEmpresa] = useState("");
  const [veículoempresa, setVeículoEmpresa] = useState("");
  const [hospedadoNoMunicipio, setHospedadoNoMunicipio] = useState(null);
  const [embarque, setEmbarque] = useState("");

  const [estado, setEstado] = useState("");
  const [telefone, setTelefone] = useState("");
  const [veiculo, setVeiculo] = useState("");

  const [placa, setPlaca] = useState("");

  const [selectedUf, setSelectedUf] = useState("0");
  const [dataCidade, setDataCidade] = useState("");
  const [dataUf, setDataUf] = useState("");

  const [cidade, setCidade] = useState("");
  const [estadouf, setEstadoUF] = useState("");
  const [pais, setPais] = useState("BR");

  const [isAllFilledVis, setIsAllFilledVis] = useState(false)
  const [jaPossuiCadastro, setJaPossuiCadastro] = useState(false);
  const [naoSeiMeuCep, setNaoSeiMeuCep] = useState(false);
  const [cep, setCep] = useState('');
  const [cepData, setCepData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [chegada, setChegada] = useState("");
  const [partida, setPartida] = useState("");
  const [salvandoCadastro, setSalvandoCadastro] = useState(false);
  
  function handleAddAcom(){
    setPossuiAcompanhante(!possuiAcompanhante);
  }

  const optionsTurismo = [
    "Turismo De Lazer",
    "Turismo De Excursão",
  ].map(item => ({ label: item, value: item }));

  const optionsSexo = [ 
    'Masculino',
    'Feminino',
    'Outros'
  ].map(item => ({ label: item, value: item }));

  // Função para remover a máscara
  const sanitizeValue = (value) => value.replace(/\D/g, ""); // Remove tudo que não for número

  const handleDateChange = (dates) => {
    setDates(dates)
    if(dates){
      const dateStart = new Date(dates[0]);
      const formattedDateStart = format(dateStart, 'yyyy-MM-dd');
      setChegada(formattedDateStart)

      const dateEnd = new Date(dates[1]);
      const formattedDateEnd = format(dateEnd, 'yyyy-MM-dd');
      setPartida(formattedDateEnd)
    }
  }

  const notifyAcomDeletado = () => toast.success('Acompanhante deletado!', {
      position: "top-right", // Posição da notificação
      autoClose: 3000,       // Fecha automaticamente após 3 segundos
      hideProgressBar: false, 
      closeOnClick: true,    
      pauseOnHover: true,    
      draggable: true,       
      progress: undefined,   
  });

  useEffect(() => {
    const dados = JSON.parse(localStorage.getItem("@cadastro:Etapa1"));

    if (dados != null) {
      setChegada(chegada);
      setPartida(partida);
      setTipoTurismo(tipoTurismo);
      setConheceMunicipio(conhecemunicipio);
    }

    const cadastro = {
      id_municipio: 1,
      tipo_turismo: tipoTurismo,
      data_chegada: chegada,
      data_partida: partida,
      conhece_municipio: conhecemunicipio,
    };

    localStorage.setItem("@cadastro:Etapa1", JSON.stringify(cadastro));
  }, [chegada, partida, tipoTurismo, conhecemunicipio]);

  const notifyVisitante = () => toast.warn('Preencha os dados do Visitante!', {
      position: "top-right", // Posição da notificação
      autoClose: 3000,       // Fecha automaticamente após 3 segundos
      hideProgressBar: false, 
      closeOnClick: true,    
      pauseOnHover: true,    
      draggable: true,       
      progress: undefined,   
  });
  
  const notifyAcompanhante= () => toast.warn('Há campos do acompanhante preenchidos! Clique LIMPAR ou termine de preencher', {
    position: "top-right", // Posição da notificação
    autoClose: 3000,       // Fecha automaticamente após 3 segundos
    hideProgressBar: false, 
    closeOnClick: true,    
    pauseOnHover: true,    
    draggable: true,       
    progress: undefined,   
  });

  const handleChangeCep = (maskedValue) => {
    const rawValue = sanitizeValue(maskedValue); // Remove máscara
    setCep(rawValue);
  };

  useEffect(() => {
    localStorage.setItem(
      "@cadastro:Etapa2",
      JSON.stringify({
        visitante: {
          nome: nome,
          sobrenome: sobrenome,
          tipoIdentificacao,
          identificacao,
          sexo,
          passaporte,
          data_nascimento: nascimento,
          compartilha_dados: compartilhaDados,
          password: newpassword,
          empresa:nomeEmpresa,
          cnpj: cnpjEmpresa,
          veículo_empresa: veículoempresa,
          tipo_turismo: tipoTurismo,
          embarque: embarque,
          veiculo: veiculo,
          placa: placa,
          pais,
          estado,
          cidade,
          telefone,
          cep
        },
      })
    );
  }, [
    nome,
    sobrenome,
    tipoIdentificacao,
    nascimento,
    identificacao,
    sexo,
    passaporte,
    compartilhaDados,
    newpassword,
    empresa,
    cnpjEmpresa,
    veiculo,
    placa,
    pais,
    cidade,
    estado,
    telefone,
    tipoTurismo,
    embarque,
    veículoempresa,
    nomeEmpresa,
    cep
  ]);

  // Pega o tamanho do dispositivo
  useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Checar no carregamento inicial
    window.addEventListener("resize", handleResize); // Listener para mudanças de tamanho

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Busca Cidade e Estado pelo CEP
  useEffect(() => {
    const fetchData = async () => {
      if(cep.length === 8){
        try {
          setIsLoading(true);
          const {data: cepData} = await axios.get('https://brasilapi.com.br/api/cep/v1/'+cep);
          setIsLoading(false);
          setEstado(cepData.state);
          setCidade(cepData.city);
          setCepData(cepData);
        } catch (error) {
          console.log(error);
        }
      }else{
        setEstado("");
        setCidade("");
        setCepData(null);
      }
    }

    fetchData();
  },[cep])

  // Preenche Nome e Sobrenome a partir de nomeCompleto
  useEffect(() => {
    // Dividir apenas no primeiro espaço
    const [primeiraParte, ...restante] = nomeCompleto.split(' ');
    const restanteString = restante.join(' ');

    setNome(primeiraParte);
    setSobrenome(restanteString);
  }, [nomeCompleto])

  //Verifica se CPF/Passaporte Cidade/Estado estão preenchidos
  useEffect(() => {
    if(jaPossuiCadastro){
      if (tipoIdentificacao === "cpf" && identificacao) {
        if(!nome || !sobrenome){
          setIsAllFilledVis(false);
          return
        }
        setIsAllFilledVis(true);
        return;
      }
    
      if (tipoIdentificacao === "passaporte" && passaporte) {
        if(!nome || !sobrenome){
          setIsAllFilledVis(false);
          return
        }
        setIsAllFilledVis(true);
        return;
      }
      
    }

    if (
      !nomeCompleto || !sexo || !nascimento || 
      !telefone || !dates || conhecemunicipio === null || hospedadoNoMunicipio === null
    ) {
      // Dados obrigatórios gerais ausentes
      setIsAllFilledVis(false);
    } else {
      if (pais === "BR") {
        // Para brasileiros, verificar cidade e estado
        if (!cidade || !estado) {
          setIsAllFilledVis(false);
          return; // Evita processar condições adicionais
        }
      }
    
      if (tipoIdentificacao === "cpf" && !identificacao) {
        setIsAllFilledVis(false);
        return;
      }
    
      if (tipoIdentificacao === "passaporte" && !passaporte) {
        setIsAllFilledVis(false);
        
        return;
      }

      setIsAllFilledVis(true);
    }
  },[nome, dates, conhecemunicipio, sobrenome, tipoIdentificacao, pais, estadouf, identificacao, passaporte, estado, cidade, nascimento, sexo, nomeCompleto, telefone, isAllFilledVis, jaPossuiCadastro])

  useEffect(() => {
    if (selectedUf === "0") {
      return;
    }
    try {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`
        )
        .then((response) => {
          setDataCidade(response.data);
        });
    } catch (err) {
      console.log("Erro ao buscar dados da api: ", err);
    }
  }, [selectedUf]);;

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados/")
      .then((response) => {
        setDataUf(response.data);
      });
  }, [pais]);

  // Verificação de usuários já cadastrados
  useEffect(() => {
    const fetchVisitante = async () => {
      try {
        if(tipoIdentificacao === 'cpf' && identificacao.length === 14){

          let docSemMascara = limparIdentificacao(identificacao);
          const {data: dados} = await API.get('/visitante/'+docSemMascara);

          if(dados.message){
            setMessage(dados.message)
          }

          if(dados.visitante){
            setNomeCompleto(dados.visitante.nome + ' ' +dados.visitante.sobrenome);
            setConheceMunicipio(true);
            setJaPossuiCadastro(true);
            setNascimento(dados.visitante.data_nascimento);
            let nomeInput = document.getElementById("nomeVisitante");
            nomeInput.disabled = true;
          }

          return;
        }else{
          setNomeCompleto("");
          setJaPossuiCadastro(false);
          let nomeInput = document.getElementById("nomeVisitante");
          nomeInput.disabled = false;
        }

        if(tipoIdentificacao === 'passaporte' && passaporte.length >= 5){

          const {data: dados} = await API.get('/visitante/'+passaporte);

          if(dados.message){
            setMessage(dados.message)
          }

          if(dados.visitante){
            setNomeCompleto(dados.visitante.nome + ' ' +dados.visitante.sobrenome);
            setConheceMunicipio(true);
            setJaPossuiCadastro(true);
            let nomeInput = document.getElementById("nomeVisitante");
            nomeInput.disabled = true;
          }

          return;
        }else{
          setNomeCompleto("");
          setJaPossuiCadastro(false);
          let nomeInput = document.getElementById("nomeVisitante");
          nomeInput.disabled = false;
        }
      } catch (error) {
        console.log(error);
      }
    }

    let nomeInput = document.getElementById("nomeVisitante");
    nomeInput.disabled = false;

    fetchVisitante();
  },[identificacao, tipoIdentificacao, passaporte])

  const handleVoltar = async () => {
    try {
      if (compartilhaDados) {
        localStorage.setItem(
          "@cadastro:Etapa2",
          JSON.stringify({
            visitante: {
              nome: nome,
              sobrenome,
              tipoIdentificacao,
              identificacao,
              passaporte,
              sexo,
              data_nascimento: nascimento,
              compartilha_dados: compartilhaDados,
              password: newpassword,
              empresa:nomeEmpresa,
              cnpj,
              veículo_empresa: veículoempresa,
              tipo_turismo: tipoTurismo,
              embarque: embarque,
              veiculo: veiculo,
              placa: placa,
              pais,
              estado,
              cidade,
              telefone,
            },
          })
        );

        navigation(-1);
      } else {
        alert("Aceite os Termos e Condições para prosseguir.");
      }
    } catch (error) {
      console.log("caiu no erro: ", error);
    }
  };

  const handleExcluirAcompanhante = useCallback((index) => {
    if (index !== -1) {
      listaAcompanhante.splice(index, 1);
      localStorage.setItem("@cadastro:Etapa3", JSON.stringify(listaAcompanhante));
      notifyAcomDeletado();
    }
  }, [])

  const handleNomeEmpresaChange = (value) => {
    const novoempresa = value;
    setNomeEmpresa(novoempresa);
  };

  const handleCnpjEmpresaChange = (value) => {
    const cnpj = value;
    setCnpjEmpresa(cnpj);
  };

  const handleCpfChange = (value, event) => {
    const novoCpf = value;
    setIdentificacao(novoCpf);
    setCpfInvalido(!validarCPF(novoCpf));
  };

  const handlePassaporteChange = (event) => {
    const novoPassaporte = event.target.value;
    setPassaporte(novoPassaporte);
    setPassaporteInvalido(!validarPassaporte(novoPassaporte));
  };

  //! Preenche id do municipio (esta com problema)
  useEffect(() => {
    const IdMunicipio = localStorage.getItem("id_municipio");

    if (IdMunicipio) {
      setIdmunicipio(IdMunicipio);
    }
  }, []);

  // Atualiza lista de acompanhantes
  useEffect(() => {
    const acoms = localStorage.getItem("@cadastro:Etapa3");
  
    if(acoms){
      if (acoms.length > 0) {
        setListaAcompanhante(JSON.parse(acoms));
      }
    }
  }, [handleExcluirAcompanhante]);

  async function handleSalvarCadastro(event) {
    setSalvandoCadastro(true);

    if(!jaPossuiCadastro){
      if(!nascimento){
        const formattedDate = format(nascimento, 'yyyy-MM-dd');
        setNascimento(formattedDate);
      }
    }
    
    if(!isAllFilledVis){
      notifyVisitante()
      setSalvandoCadastro(false);
      return
    }

    if(isAcomPartialFilled){
      notifyAcompanhante();
      setSalvandoCadastro(false);
      return
    }

    event.preventDefault();

    localStorage.setItem(
      "@cadastro:Etapa5",
      JSON.stringify({
        pais,
        estadouf,
        estadoufh,
        cidade,
        acomodacao: hospedagem,
        nomeAcomodacao,
        hospital,
        nomeHospital,
        telhospedagem,
        endereco,
        tipo_turismo: tipoTurismo,
        tipo_roteiro: tiporoteiro,
        viajando_com: viajandoCom,
        aceita_pet: PetFriendly,
      })
    );

    const etapaP = await JSON.parse(localStorage.getItem("@passagem:Etapa"));
    const etapa1 = await JSON.parse(localStorage.getItem("@cadastro:Etapa1"));
    const etapa2 = await JSON.parse(localStorage.getItem("@cadastro:Etapa2"));
    const etapa3 = await JSON.parse(localStorage.getItem("@cadastro:Etapa3"));
    const etapa4 = await JSON.parse(localStorage.getItem("@cadastro:Etapa4"));
    const etapa5 = await JSON.parse(localStorage.getItem("@cadastro:Etapa5"));

    setIdentificacaoTitular(etapa2.visitante.identificacao);

    const data = {
      id_municipio: id_municipio,
      tipo_turismo: etapa1.tipo_turismo,
      viajando_com: etapa1.viajando_com,
      conhece_municipio: etapa1.conhece_municipio,
      data_chegada: etapa1.data_chegada,
      data_partida: etapa1.data_partida,
      uf_hospedado: etapa1.uf_hospedado || "",
      cidade_hospedado: etapa1.cidade_hospedado || "",
      empresa: etapa2.visitante.empresa,
      aceita_pet: PetFriendly,
      cnpj_empresa: etapa2.visitante.cnpj,
      tipo_roteiro: etapa1.tipo_roteiro || 'N',
      hospedado_no_municipio: hospedadoNoMunicipio,

      visitante: {
        nome: etapa2.visitante.nome,
        sobrenome: etapa2.visitante.sobrenome,
        tipoIdentificacao: etapa2.visitante.tipoIdentificacao,
        identificacao: etapa2.visitante.identificacao,
        data_nascimento: etapa2.visitante.data_nascimento,
        sexo: etapa2.visitante.sexo,
        escolaridade: etapa2.visitante.escolaridade,
        compartilha_dados: etapa2.visitante.compartilha_dados,
        pais: etapa2.visitante.pais,
        cidade: etapa2.visitante.cidade,
        estado: etapa2.visitante.estado,
        email: etapa2.visitante.email,
        telefone: etapa2.visitante.telefone,
        nacionalidade: etapa2.visitante.nacionalidade,
        password: etapa2.visitante.password,
        passaporte: etapa2.visitante.passaporte,
        cep: cep || ''
      },

      acompanhante: etapa3,

      acomodacao: {
        tipo: hospedagem || "Vazio",
        nome: nomeAcomodacao || "Vazio",
      },

      transporte: {
        tipo: etapa2.visitante.veiculo,
        placa: etapa2.visitante.placa,
      },

      // emergencia: {
      //   nome: etapa4.nome || '',
      //   parentesco: etapa4.parentesco || '',
      //   telefone: etapa4.emergenciaTel || '',
      // },

      passagem: {
        hospedado_outro_municipio:
          tiporoteiro === "Estou hospedado em outro município" ? true : false,
        nao_hospedado:
          tiporoteiro === "Estou hospedado em outro município" ? false : true,
      },

      pet: {
        aceita_pet: PetFriendly,
      },

      agencia: agencia,
    };

    setMensagem("");

    validaCampos
      .validate(data)
      .then((valid) => {
        console.log("Validado: ", data);

        API.post("/viagemNew", data)
          .then((result) => {
            //localStorage.clear();
            navigation(
              `/concluido/${result.data.newViagem.id}/${result.data.newViagem.data_chegada}/${result.data.newViagem.data_partida}`
            );
          })
          .catch((error) => {
            alert(error.message);
          });
      })
      .catch((err) => {
        setMensagem(err.message);
        setSalvandoCadastro(false);
        console.log("Erro: ", err.message);
      });
    setSalvandoCadastro(false);
  }

  return (
    <form className="container" onSubmit={handleVoltar}>
      <section className="home_topo_conteudo">
        <img src={LogoColor} alt="logo" className="logoColor" />
        <img src={LogoColor} alt="logo" className="logo" />
      </section>

      <section className="corpo">
        <div className="text1-black">Cadastro do visitante</div>
        <div className="text2">
          Preencha os dados do perfil. Isso levará alguns segundos.
          <br />
          <strong>
            Depois ganhe tempo na visitação dos pontos turísticos!
          </strong>
        </div>

        <div className="">
          <div >
            <div className="gridDuasColunas">
              <div>
                <div className="TitleCampo">Tipo de viagem</div>
                  <InputPicker 
                    data={optionsTurismo}
                    onChange={(value) => setTipoTurismo(value)}
                    value={tipoTurismo}
                    style={{width: '100%', border: '1.5px solid #e5e5ea' }}
                    required={true}
                  />
              </div>

              <div>
                <div className="TitleCampo">Período de permanência?</div>
                  <DateRangePicker style={{fontFamily: 'Poppins-Regular'}}  onchange={handleDateChange} dates={dates}/>
                </div>
              </div>
            </div>

            {
              tipoTurismo === "Turismo De Excursão" ? (
                <>
                  <div className="text1-black">Informações da Empresa</div>

                  <div className="gridDuasColunas">
                    <Input
                      id="NomeEmpresa"
                      placeholder="Nome da Empresa"
                      value={nomeEmpresa}
                      onChange={handleNomeEmpresaChange}
                      required={true}
                      style={{border: '1.5px solid rgba(221, 221, 221, 1)'}}
                    />

                    <MaskedInput
                      placeholder="CNPJ da Empresa"
                      value={cnpjEmpresa}
                      mask={[
                        /\d/, /\d/, '.', 
                        /\d/, /\d/, /\d/, '.', 
                        /\d/, /\d/, /\d/, '/', 
                        /\d/, /\d/, /\d/, /\d/, '-', 
                        /\d/, /\d/
                      ]}  
                      onChange={handleCnpjEmpresaChange}
                      required={true}
                      style={{border: '1.5px solid rgba(221, 221, 221, 1)'}}
                    />
                  </div>
                </>
              )
              :
              (
                <></>
              )
            }

            <div style={{display: 'flex', flexDirection: 'column ', marginBottom: '1rem', marginTop: '2rem'}}>
              <div className="" style={{marginBottom: '1rem', fontFamily: 'Poppins-medium'}}>Documentação</div>
              <div style={{display: 'flex'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <input
                    type="radio"
                    value="cpf"
                    name="identVis"
                    checked={tipoIdentificacao === "cpf"}
                    defaultChecked={true}
                    onChange={(item) => setTipoIdentificacao(item.target.value)}
                  />
                  <label style={{marginLeft: '1rem'}} className="textRadio">CPF</label>
                </div>
                <div  style={{display: 'flex', alignItems: 'center', marginLeft: '2rem'}}>
                  <input
                    type="radio"
                    value="passaporte"
                    name="identVis"
                    checked={tipoIdentificacao === "passaporte"}
                    onChange={(item) => setTipoIdentificacao(item.target.value)}
                  />
                  <label style={{marginLeft: '1rem'}} className="textRadio">Passaporte</label>
                </div>
              </div>
            </div>

          <div className="gridDuasColunas">
            {tipoIdentificacao === "cpf" && (
              <div>
                <div className="textInput">CPF</div>
                <MaskedInput
                  placeholder="Número do CPF"
                  mask={[
                    /\d/, /\d/, /\d/, '.', 
                    /\d/, /\d/, /\d/, '.', 
                    /\d/, /\d/, /\d/, '-', 
                    /\d/, /\d/
                  ]}
                  style={{border: '1.5px solid rgba(221, 221, 221, 1)'}}
                  value={identificacao}
                  onChange={handleCpfChange}
                />
                {
                  cpfInvalido && tipoIdentificacao === "cpf" ? (
                    <p style={{ color: "red" }}>CPF inválido. Por favor, verifique.</p>
                  )
                  :
                  (<></>)
                }
                {
                  (message && identificacao && identificacao.length === 14) ? 
                  (
                    <p style={{ color: "red" }}>{message}</p>
                  )
                  :
                  (
                    <></>
                  )
                }
              </div>
            )}

            {tipoIdentificacao === "passaporte" && (
              <div>
                <div className="textInput">Passaporte</div>
                <Input
                  placeholder="Número do passaporte"
                  value={passaporte}
                  onChange={handlePassaporteChange}
                />
                {passaporteInvalido && tipoIdentificacao === "passaporte" ? (
                  <p style={{ color: "red" }}>Passaporte inválido. Por favor, verifique.</p>
                )
                :
                (<></>)
                }
                {
                  (message && passaporte && passaporte.length >=5) ? 
                  (
                    <p style={{ color: "red" }}>{message}</p>
                  )
                  :
                  (
                    <></>
                  )
                }
              </div>
            )}
            
           
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div className="textInput">Nome completo</div>
                <Input
                  id="nomeVisitante"
                  placeholder="Nome Completo"
                  value={nomeCompleto}
                  onChange={(value) => setNomeCompleto(value)}
                  required={true}
                />
                {
                  nomeCompleto && nomeCompleto.trim().split(' ').length < 2 && (
                    <><span style={{color:'red'}}>Escreva nome e sobrenome</span></>
                  )
                }
              </div>
            </div>
        </div>

        {
          !jaPossuiCadastro ?
          (
            <>
              <div className="gridDuasColunas" style={{marginTop:'2rem'}}>
                <div>
                  <div className="textInput">Data de Nascimento</div>
                  <DatePicker
                    onChange={newValue => {
                      setNascimento(newValue);
                    }}
                    style={{width: '100%'}}
                  />
                </div>

                <div>
                  <div className="textInput">Qual seu sexo</div>
                  <InputPicker 
                    data={optionsSexo}
                    onChange={(value) => setSexo(value)}
                    value={sexo}
                    style={{ 
                      width: "100%", 
                      display: "flex", 
                      alignItems: "center", 
                    }}
                    required={true}
                  />
                </div>
              </div>
              
              <div style={{marginTop: '2rem'}}>
                <div className="gridDuasColunas">
                  <div  style={{display: 'flex', flexDirection: 'column'}}>
                    <div className="textInput">Qual País você mora?</div>
                    <PaisPicker setPais={setPais} pais={pais}/>
                  </div>

                  <div>
                  {
                    pais === "BR"  ?
                    (
                      <>
                        {
                          naoSeiMeuCep ? 
                          (
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                              <div className="textInput">Insira seu CEP</div>
                              <div className="cepInputContainer" style={{flexDirection:'column', width:'100%'}}>
                                <MaskedInput 
                                  value={cep}
                                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
                                  onChange={handleChangeCep}
                                  disabled
                                />
                                <Checkbox checked={naoSeiMeuCep} onChange={(value, checked) => {setNaoSeiMeuCep(checked)}}>Não sei meu CEP</Checkbox>
                              </div>
                            </div>
                          )
                          :
                          (
                            <>
                              <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div className="textInput">Insira seu CEP</div>
                                <div className="cepInputContainer" style={{flexDirection:'column', width:'100%'}}>
                                  <MaskedInput 
                                    value={cep}
                                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
                                    onChange={handleChangeCep}
                                  />
                                  {
                                    isLoading && (
                                      <>
                                        <Loader vertical content={'Buscando cidade...'} style={{maginLeft: '1rem'}}/>
                                      </>
                                    )
                                  }
                                  <Checkbox checked={naoSeiMeuCep} onChange={(value, checked) => {setNaoSeiMeuCep(checked)}}>Não sei meu CEP</Checkbox>
                                </div>
                              </div>
                            </>
                          )
                        }
                      </>
                    )
                    :
                    (
                      <></>
                    )
                  }
                </div>
                  
                </div>
                {
                  naoSeiMeuCep ? 
                  (
                    <EstadoCidadePicker  setEstado={setEstado} setCidade={setCidade} cidade={cidade} estado={estado}/>
                  )
                  :
                  (
                    cepData && (
                      <div style={{display: 'flex', gap: '20px'}} >
                        <Input
                          id="estadoVisitante"
                          title="Estado"
                          placeholder=""
                          value={cepData.state}
                          disabled
                        />
                        <Input
                          id="cidadeVisitante"
                          title="Cidade"
                          placeholder=""
                          value={cepData.city}
                          disabled
                        />
                      </div>
                    )
                  )
                }
              </div>

              <div className="phoneContainer" style={{marginTop: '2rem'}}>
                <div className="textInput">Telefone</div>
                <PhoneInput 
                  country={'br'}
                  value={telefone}
                  onChange={(e) => setTelefone(e)}
                  containerStyle={{
                    borderRadius: '100px',
                    width: '100%',
                  }}
                  inputStyle={{
                    // height: '35px',
                    fontSize: '14px',
                    paddingLeft: '40px',
                    width: '100%',
                    height: '7rem'
                  }}
                /> 
                  </div>
            </>
          )
          :
          (<></>)
        } 

        <div className="jaVisitouContainer" style={{fontFamily:"Poppins-medium"}}>
          <div>
            <div className="TitleCampo">Está hospedado em Capitólio?</div>
            <div className="jaVisitouOpcoes">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={hospedadoNoMunicipio === true} // Igual ao estado
                  onChange={() => setHospedadoNoMunicipio(true)} // Marca este checkbox
                >
                  Sim
                </Checkbox>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={hospedadoNoMunicipio === false} // Inverso do estado
                  onChange={() => setHospedadoNoMunicipio(false)} // Marca este checkbox
                >
                  Não
                </Checkbox>
              </div>
            </div>
          </div>
          <div>
            <div className="TitleCampo" style={{color: '#343434'}}>Sua primeira visita?</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={conhecemunicipio === false} // Igual ao estado
                onChange={() => setConheceMunicipio(false)} // Marca este checkbox
              >
                Sim
              </Checkbox>
              <Checkbox
                checked={conhecemunicipio === true} // Igual ao estado
                onChange={() => setConheceMunicipio(true)} // Marca este checkbox
              >
                Não
              </Checkbox>
            </div>
          </div>
            
        </div>

        <div style={{margin: '3rem auto', width: '80%'}} >
            {
              listaAcompanhante.length > 0 && (
                <div className="titleinput">Acompanhante(s)</div>
              )
            }
            {listaAcompanhante && listaAcompanhante.map((item, index) => (
              <>
                <div
                    key={index}
                    className="acompanhanteItem"
                    >
                    {
                        isMobile ? 
                        (
                          <></>
                        )
                        :
                        (
                            <>
                                <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex', borderRadius: '5rem', padding: '1.5rem', backgroundColor: '#222222' }}>
                                    <FaUser size={40}  style={{color: "#FFF"}}/>
                                </div>
                            </>
                        )
                    }
                        
                        <div style={{ flex: 4, marginLeft: '4%', paddingBottom: 3,  width:'100%'}}>
                            <p style={{  fontFamily: 'Poppins-Regular', color: '#222222', fontSize: 13, textTransform: 'capitalize' }}>
                            {item.nome} {item.sobrenome}
                            </p>
                            <p style={{ fontFamily: 'Poppins-Regular', color: '#C13515', fontSize: 11 }}>{item.cpf ?? ''}</p>
                        </div>

                        <div 
                          style={{display: 'flex', alignItems: 'center', justifyContent:'center', marginLeft:'4px'}} 
                          className="btn deletar poppins" 
                          onClick={() => handleExcluirAcompanhante(index)}>
                            Remover
                        </div>
                </div>
              </>
          ))}
        </div>
      
      {
        !possuiAcompanhante && (
          <div className="containerbloco" style={{display: 'flex', alignItems: 'center', marginBottom: '2rem', marginTop: '3rem'}}>
            <Button className="botaoAddAcom" appearance="primary" onClick={handleAddAcom} block>Adicionar acompanhante</Button>
          </div>
        )
      }

      {
        possuiAcompanhante  && (
          <>
            {/*CADASTRO DO ACOMPANHANTES*/}
            {viajandoCom !== "Sozinho" && (
              <div className="" style={{width: '100%'}}>
                <CadastroAcompanhante 
                  tipoTurismo={tipoTurismo} 
                  identificacaoTitular={identificacaoTitular} 
                  setIsAcomPartialFilled={setIsAcomPartialFilled}
                  listaAcompanhante={listaAcompanhante}
                  setlistaAcompanhante={setListaAcompanhante}
                  setPossuiAcompanhante={setPossuiAcompanhante}
                  isAcomPartialFilled={isAcomPartialFilled}
                />
              </div>
            )}
          </>
        )
      }

      {
        (isAllFilledVis && !isAcomPartialFilled) || salvandoCadastro ?
        (
          <>
            <button
              className="btncadastrar"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
              }}
              onClick={handleSalvarCadastro}
            >
              Salvar Cadastro
            </button>
          </>
        )
        :
        (
          <>
            <button
              className="btncadastrarDisabled"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
              }}
              disabled
              onClick={handleSalvarCadastro}
            >
              Salvar Cadastro
            </button>
          </>
        ) 
      }

      <div className="containerPolitica flex">
          <input
            type="checkbox"
            className="checkbox"
            checked={compartilhaDados}
            onChange={(item) => setCompartilhaDados(item.target.checked)}
          />
          <div style={{marginLeft: '1rem'}}>
            <div className="politica">
              <span>Compartilhar meus dados de registro com nossos provedores de
              conteúdo para fins de analíses turísticas.</span>
              <br />
              Ao preencher os dados, você concorda com os{" "}
              <Link className="a" to={"/TermosdeUso"}>
                <strong>Termos de uso</strong>,
              </Link>
              
              <Link className="a" to={""}>
                <strong>Termos do Município</strong>,
              </Link>
              
              <Link className="a" to={"/PoliticaDePrivacidade"}>
                <strong>Política de Privacidade.</strong>
              </Link>
            </div>
          </div>
        </div>

        <div> 
          <div
            className="text2"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "red",
              fontWeight: "bold",
            }}
          >
            {mensagem}
          </div>
        </div>
      </section>
    </form>
  );
};

export default CadastroVisitante;