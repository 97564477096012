import { useEffect, useState } from "react";
import "./Popup.css"; // Arquivo para os estilos do popup
import { useNavigate, useParams } from "react-router-dom";
import LogoColor from "../../assets/image/logo_color.svg";
import Dropdown from "../../components/Dropdown";
import Rodape from "../../components/Rodape";
import InputText from "../../components/InputText";
import { BuscaEstados } from "../../models/buscaEstados";
import DropdownEstadoUF from "../../components/DropdownEstadoUF";
import DropdownCidade from "../../components/DropdownCidade";
import axios from "axios";
import { format } from 'date-fns';
import DateRangePicker from "../../components/RangeDataPicker";

const CadastroNovo = () => {
  const navigation = useNavigate();

  const [chegada, setChegada] = useState("");
  const [partida, setPartida] = useState("");
  const [dates, setDates] = useState([])
  const [viajandoCom, setViajandoCom] = useState("");
  const [tipoturismo, setTipoTurismo] = useState("");
  const [conhecemunicipio, setConheceMunicipio] = useState("");
  const [tiporoteiro, setTipoRoteiro] = useState("");

  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [siglaUF, setSiglaUF] = useState("");
  const [dataCidade, setDataCidade] = useState([]);
  const [dataEstado, setDataEstado] = useState([]);

  const [isAllFilledViagem, setIsAllFilledViagem] = useState(false);
  

  const { id } = useParams();

  const cadastro = {
    id_municipio: id,
    tipo_turismo: tipoturismo,
    viajando_com: viajandoCom,
    data_chegada: chegada,
    data_partida: partida,
    conhece_municipio: conhecemunicipio,
    tipo_roteiro: tiporoteiro,
    uf_hospedado: estado,
    cidade_hospedada: cidade,
  };

  const optionsTurismo = [
    { key: 1, value: "Turismo Comercial" },
    { key: 2, value: "Turismo De Lazer" },
    { key: 3, value: "Turismo De Excursão" },
    { key: 4, value: "Visita Familiar" },
  ];

  const optionsViajandoCom = [
    { key: 1, value: "Família" },
    { key: 2, value: "Amigos" },
    { key: 3, value: "Sozinho" },
    { key: 4, value: "Grupo de excursão"}
  ];

  const optionsRoteiro = [
    { key: 1, value: "Estou hospedado em outro município" },
    { key: 2, value: "Estou só de passagem" },
    { key: 3, value: "Vou/Estou hospedado no município" },
    { key: 4, value: "Sou residente no município" }
  ];

  

  useEffect(() => {
    // Verificação inicial para campos obrigatórios
    if (!tipoturismo || !dates || !conhecemunicipio || !tiporoteiro || !viajandoCom || !partida || !chegada) {
      setIsAllFilledViagem(false);
      return;
    }
  
    // Verificação adicional se o tipo de roteiro for "Estou hospedado em outro município"
    if (tiporoteiro === "Estou hospedado em outro município") {
      if (!estado || !cidade) {
        setIsAllFilledViagem(false);
        return;
      }
    }
  
    // Se passou em todas as verificações, define como preenchido
    setIsAllFilledViagem(true);
  }, [tipoturismo, dates, conhecemunicipio, tiporoteiro, viajandoCom, estado, cidade, partida, chegada]);
  
 

  useEffect(() => {
    const fetchdata = async () => {
      const res = await BuscaEstados();
      if (res.length > 0) {
        setDataEstado(res);
      }
    };
    fetchdata();
  }, []);

  useEffect(() => {
    const fetchdata = async () => {
      // const res = await BuscaMunicipios(estado);
      const res = await axios.get(`https://brasilapi.com.br/api/ibge/municipios/v1/${siglaUF}?providers=dados-abertos-br,gov,wikipedia`)
      if (res.data.length > 0) {
        setDataCidade(res.data);
      }
    };
    if (estado && estado.length > 0) {
      fetchdata();
    }
  }, [estado]);

  useEffect(() => {
    const dados = JSON.parse(localStorage.getItem("@cadastro:Etapa1"));
    localStorage.setItem("id_municipio", id);
    if (dados != null) {
      setChegada(dados.data_chegada);
      setPartida(dados.data_partida);
      setViajandoCom(dados.viajando_com);
      setTipoTurismo(dados.tipo_turismo);
      setConheceMunicipio(dados.conhece_municipio);
      setTipoRoteiro(dados.tipo_roteiro);
    }
  }, []);

  const handleAvanca = () => {
    localStorage.setItem("@cadastro:Etapa1", JSON.stringify(cadastro));
    navigation("/visitante/");
  };

  const handleOptionChange = (e) => setConheceMunicipio(e.target.value);

  const handleDateChange = (dates) => {
    setDates(dates)
    if(dates){
      const dateStart = new Date(dates[0]);
      const formattedDateStart = format(dateStart, 'yyyy-MM-dd');
      setChegada(formattedDateStart)

      const dateEnd = new Date(dates[1]);
      const formattedDateEnd = format(dateEnd, 'yyyy-MM-dd');
      setPartida(formattedDateEnd)
    }
  }

  return (
    <form className="container" onSubmit={handleAvanca}>
      <section className="home_topo_conteudo">
        <img src={LogoColor} alt="logo" className="logoColor" />
        <img src={LogoColor} alt="logo" className="logo" />
      </section>

    
      <section className="corponovo flex">

        <div className="ContainerTitulo marginBotton">
          <div className="banner">Me conte mais sobre a viagem!</div>
        </div>

        <div className="containerbloco">
          <div className="TitleCampo">Tipo de roteiro</div>
          <Dropdown
            data={optionsRoteiro}
            onchange={(item) => setTipoRoteiro(item.target.value)}
            value={tiporoteiro}
            msnWarning="Selecione o roteiro."
            required
          />
        </div>

        {tiporoteiro === "Estou hospedado em outro município" && (
          <div className="containerbloco">
            <div className="TitleCampo">
              Em qual cidade você está hospedado?
            </div>

            <div className="containerbloco col-5 flex">
              <div>
                <div className="textInput">Estado</div>

                <DropdownEstadoUF
                  data={dataEstado}
                  placeholder=""
                  msnWarning="Selecione o Estado."
                  value={estado}
                  onchange={(event) => {
                    const selectedOption = event.target.options[event.target.selectedIndex];
                    setEstado(event.target.value);  
                    setSiglaUF(selectedOption.getAttribute("data-extra"));
                  }}
                  required={true}
                />
              </div>

              {estado ? (
                <div style={{ width: "100%" }}>
                  <div className="textInput">Cidade</div>
                    <DropdownCidade
                      data={dataCidade}
                      placeholder=""
                      msnWarning="Selecione a cidade"
                      value={cidade}
                      onchange={(item) => setCidade(item.target.value)}
                      required={true}
                    />
                </div>
              ) : (
                <div>
                  <InputText
                    title="Cidade"
                    placeholder="Digite o nome da cidade"
                    value={estado}
                    onchange={(item) => {setCidade(item.target.value); console.log(dates)}}
                    msnWarning="Obrigatório preencher a cidade"
                    required={true}
                    disabled={true}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <div className="col-5 flex">
          <div>
            <div className="TitleCampo">Tipo de viagem</div>
            <Dropdown
              data={optionsTurismo}
              //placeholder="Selecione o motivo"
              onchange={(item) => setTipoTurismo(item.target.value)}
              value={tipoturismo}
              msnWarning="Selecione o tipo de turismo realizado."
              required={true}
            />
          </div>

          <div>
            <div className="TitleCampo">Você está viajando com</div>
            <Dropdown
              data={optionsViajandoCom}
              //placeholder="Selecione"
              onchange={(item) => setViajandoCom(item.target.value)}
              value={viajandoCom}
              msnWarning="Selecione se possui mais alguém com você."
              required={true}
            />
          </div>
        </div>

        <div className="containerbloco">
          <div className="TitleCampo">Qual o seu período de permanência?</div>
          <div className="col-6 flex">
            <DateRangePicker onchange={handleDateChange} dates={dates}/>
          </div>
        </div>

        <div className="containerbloco" style={{marginTop: '2rem'}}>
          <div className="TitleCampo">Você já vistou nosso município</div>
          <div className="col-2 flex">
            <div style={{ display: 'flex', alignItems: 'center'}}>
              <input
                type="radio"
                value="false"
                name="visitei"
                checked={conhecemunicipio === "false"}
                onChange={handleOptionChange}
              />
              <label style={{marginLeft: '1rem'}} className="textvisita">Não! É a primeira visita!</label>
            </div>
            <div style={{ display: 'flex', alignItems: 'center'}}>
              <input
                type="radio"
                value="true"
                name="visitei"
                checked={conhecemunicipio === "true"}
                onChange={handleOptionChange}
              />
              <label className="textvisita" style={{marginLeft: '1rem', margin: 'auto 1rem'}}>
                Sim! Já visitei outras vezes!
              </label>
            </div>
          </div>
        </div>

       
      </section>
      <Rodape valorBar={50} voltar={() => navigation(-1)} avancar={isAllFilledViagem}/>
    </form>
  );
};
export default CadastroNovo;
