import React, { useState, useEffect } from "react";
import { InputPicker } from "rsuite";
import axios from "axios";
import './cidadeEstadoPicker.css'

const estados = [
  { sigla: "AC", nome: "Acre" },
  { sigla: "AL", nome: "Alagoas" },
  { sigla: "AM", nome: "Amazonas" },
  { sigla: "BA", nome: "Bahia" },
  { sigla: "CE", nome: "Ceará" },
  { sigla: "DF", nome: "Distrito Federal" },
  { sigla: "ES", nome: "Espírito Santo" },
  { sigla: "GO", nome: "Goiás" },
  { sigla: "MA", nome: "Maranhão" },
  { sigla: "MG", nome: "Minas Gerais" },
  { sigla: "MS", nome: "Mato Grosso do Sul" },
  { sigla: "MT", nome: "Mato Grosso" },
  { sigla: "PA", nome: "Pará" },
  { sigla: "PB", nome: "Paraíba" },
  { sigla: "PE", nome: "Pernambuco" },
  { sigla: "PI", nome: "Piauí" },
  { sigla: "PR", nome: "Paraná" },
  { sigla: "RJ", nome: "Rio de Janeiro" },
  { sigla: "RN", nome: "Rio Grande do Norte" },
  { sigla: "RO", nome: "Rondônia" },
  { sigla: "RR", nome: "Roraima" },
  { sigla: "RS", nome: "Rio Grande do Sul" },
  { sigla: "SC", nome: "Santa Catarina" },
  { sigla: "SE", nome: "Sergipe" },
  { sigla: "SP", nome: "São Paulo" },
  { sigla: "TO", nome: "Tocantins" },
];

export default function EstadoCidadePicker({setEstado, setCidade, estado, cidade}) {
  const [cidades, setCidades] = useState([]);
  // Carrega as cidades quando um estado é selecionado
  useEffect(() => {
    if (estado) {
      axios
        .get(`https://brasilapi.com.br/api/ibge/municipios/v1/${estado}`)
        .then((response) => {
          const cidadesData = response.data.map((cidade) => ({
            label: cidade.nome,
            value: cidade.nome,
          }));
          setCidades(cidadesData);
        })
        .catch((error) => {
          console.error("Erro ao buscar cidades:", error);
          setCidades([]);
        });
    } else {
      setCidades([]);
    }
  }, [estado]);

  return (
    <div className="gridDuasColunas">
      {/* InputPicker para selecionar estado */}
      <InputPicker
        id="estado"
        data={estados.map((estado) => ({
          label: estado.nome,
          value: estado.sigla,
        }))}
        placeholder="Selecione um estado"
        onChange={setEstado}
        value={estado}
        searchable
        cleanable
      />

      {/* InputPicker para selecionar cidade */}
      {estado && (
        <>
          <InputPicker
            id="cidade"
            data={cidades}
            placeholder="Selecione uma cidade"
            onChange={setCidade}
            value={cidade}
            searchable
            cleanable
          />
        </>
      )}
    </div>
  );
}
