import React, { useState, useEffect } from "react";
import { InputPicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "./paisPickerStyle.css"; 
import paisesData from "./../assets/paises.json"

export default function PaisPicker({pais, setPais}) {
  const [paises, setPaises] = useState([]);
  const [buscaPais, setBuscaPais] = useState("");

  useEffect(() => {
    const fetchPaises = async () =>{
      try {

        const paisesFormatados = paisesData.map((pais) => ({
          label: pais.translations.por?.common || pais.name.common, 
          value: pais.cca2, 
        }));

        setPaises(paisesFormatados);
      } catch (error) {
        console.log(error);
      }
    }

    fetchPaises();
  }, []);

  // Filtra os países com base no input do usuário, sem normalizar acentos
  const paisesFiltrados = paises.filter((pais) =>
    pais.label.toLowerCase().includes(buscaPais.toLowerCase())
  );

  return (
    <InputPicker
      id="pais"
      data={paisesFiltrados}
      placeholder="Digite para buscar um país"
      onSearch={(value) => setBuscaPais(value)}
      onChange={setPais}
      value={pais || 'BR'}
      searchable
      cleanable
    />
  );
}
