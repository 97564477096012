import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css";
import AppRoutes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './service/JwtContext';
import { CustomProvider } from "rsuite";
import { ptBR } from 'rsuite/esm/locales';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CustomProvider locale={ptBR}>
    <React.StrictMode>
    
      <AuthProvider>
        <BrowserRouter>
          <AppRoutes/>
        </BrowserRouter>
      </AuthProvider>
    </React.StrictMode>
  </CustomProvider>
);

