import React, { useState } from "react";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker, Modal, Button } from "rsuite";
import ptBR from "date-fns/locale/pt-BR";
import './DateRangePickerResponsivo.css'


const ResponsiveDateRangePicker = ({ onchange, dates, style }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // Verifica se a tela é pequena
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Checar no carregamento inicial
    window.addEventListener("resize", handleResize); // Listener para mudanças de tamanho

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
        {isMobile ? (
            <>
              {/* Botão para abrir o modal no mobile */}
              <Button style={{width: '100%', fontFamily: 'Poppins-medium', backgroundColor: 'white'}} onClick={() => setModalOpen(true)}>
                {dates?.length > 0
                  ? `${dates[0]?.toLocaleDateString()} - ${dates[1]?.toLocaleDateString()}`
                  : "DD/MM/AAAA - DD/MM/AAAA"}
              </Button>

              {/* Modal */}
              <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                size="lg" // Define um tamanho padrão pequeno
                style={{
                  maxWidth: "100vw", // Garante que o modal nunca ultrapasse a largura da tela
                  maxHeight: "100vh", // Garante que o modal não ultrapasse a altura da tela
                  overflowY: "auto", // Permite rolagem se o conteúdo for maior que a tela
                  padding: "0 10px", // Adiciona um pequeno espaçamento interno
                }}
              >
                <Modal.Header>
                  <Modal.Title>Selecione as Datas</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflowY: "auto", padding: "10px" }}>
               
                  <DateRangePicker
                    showOneCalendar
                    style={{ width: "100%"}} // Garante que o picker ocupe toda a largura disponível
                    placeholder="DD/MM/AAAA - DD/MM/AAAA"
                    value={dates}
                    onChange={onchange}
                    placement="auto"
                    locale={ptBR}
                  />
                
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => setModalOpen(false)} appearance="primary">
                    Confirmar
                  </Button>
                  <Button onClick={() => setModalOpen(false)} appearance="subtle">
                    Cancelar
                  </Button>
                </Modal.Footer>
              </Modal>
              
            </>
        ) : (
          // Exibição normal em telas maiores
          
          <DateRangePicker
            style={style}
            placeholder="DD/MM/AAAA até DD/MM/AAAA"
            value={dates}
            onChange={onchange}
            placement="auto"
            locale={ptBR}
          />
         
        )}
    </div>
  );
};

export default ResponsiveDateRangePicker;
