import "./InputText.css";
import Warning from "../assets/image/warning.png";

const InputText = ({ id, title, placeholder, value, onchange, type, msnWarning, required, autocomplete, disabled, customStyle, oninput }) => {
    return (
        <section className="box">
            <div className="textInput">{title}</div>
            <input
                id={id}
                required={required}
                className="input"
                type={type ? type : "text"}
                placeholder={placeholder}
                value={value}
                onChange={onchange}
                onInput={oninput}
                autoComplete={autocomplete} // Adicione o atributo autocomplete
                disabled={disabled ? true : false}
                style={customStyle ?? {}}
            />
            <div className="warning">
                <img src={Warning} alt="ícone de alerta" />
                {msnWarning}
            </div>
        </section>
    );
};

export default InputText;
